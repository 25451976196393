.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  position: relative;
  z-index: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 400px) {
  /* #valueproposition {
    padding: 20px;
    width: 111%;
  } */
  /* #valueproposition,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
    padding: 10px;
  } */

  #header .intro-text h1 {
    animation: moveUp 2.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    font-size: 35px;
    font-family: "Poster", sans-serif;
  }

  #challenges {
    padding: 10px;
  }
  .challenges-item .content-wrapper {
    padding: 10px;
  }

  #aboutSpectrometer {
    padding: 10px;
  }
  .spectrometer-item .content-wrapper {
    padding: 10px;
  }

  #inlineMonitoring {
    padding: 10px;
  }
  .inline-item .content-wrapper {
    padding: 10px;
  }

  #portfolio {
    width: 110%;
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

.animated {
  animation: moveUp 2s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}
