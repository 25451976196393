.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
}

.background-video {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  text-align: center;
  padding: 20px;
  z-index: 1;
}

.overlay h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.overlay p {
  font-size: 1.5em;
}

.video-container.fadedin .background-video {
  opacity: 0.8; /* Adjust opacity as desired - faded in effect*/
}
